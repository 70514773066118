$primary-font-color: #727274;
$secondary-font-color: #069FCD;



// Navbar section 
.navbar-nav .nav-item a {
    color: black;
    margin-right: 2rem;
}
  
.navbar-section {
    border-bottom: 1px solid #e2e2e2;
    position: sticky;
    width: 100%;
    background-color: white;
    top: 0px;
    /* to stop other elemnt to overlay the number, we added z-index */
    z-index: 1;
 }
  
.navbar-section.blurred {
    background-color: white; 
    // background-color: rgb(255, 255, 255, 0.4);
  
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  

  //

  .subtitle{
    color: $secondary-font-color;
  }

.rounded-corner {
    border-radius: 1rem;
    border: 0px;
}


//

.contact-us-text{
    text-decoration: none;
    color: $primary-font-color;
}

 // cards styling 
 .card-innner-padding{
    padding: 4rem 2rem;
  }



  body{
    background-color: #F5F5F7;
  }